import React from 'react';
import "./style.css";
function CircleText({ name, personImage }) {
  return (
    <div className="rounded-text rotating">
      {name ? <span>{name}</span> : null}
      <img src={personImage} alt="" />
      {/* <svg viewBox="0 0 200 200">
        <path
          id="textPath"
          d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0"
          transform="translate(100,100)"
          fill="none"
          strokeWidth="0"
        ></path>
        <g fontSize="13.1px">
          <text textAnchor="start">
            <textPath
              className="coloring"
              xlinkHref="#textPath"
              startOffset="100%"
            >
              {name}
            </textPath>
          </text>
        </g>
      </svg> */}
    </div>
  );
}

export default CircleText;
