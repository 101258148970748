import React from 'react';
import FormContect from '../../components/form';
import "./style.css"
function Home() {
  return (
    <section id="home">
      <div className="bg-image"></div>
      <FormContect />
    </section>
  );
}

export default Home;
