import React from 'react';
import ClientQR from '../clientQR';
import CircleText from '../circleText';
import "./style.css";
function PersonData({ name, personImage, qrImage }) {
  return (
    <div className="personData">
      <CircleText name={name} personImage={personImage} />
    </div>
  );
}

export default PersonData;
