import React, { useEffect, useState } from 'react';
import "./style.css";
import 'react-toastify/dist/ReactToastify.css';
import PersonData from '../personData';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
function FormContect() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sentData, setSentData] = useState({});
  useEffect(() => {
    const decodedData = urlSearchParams.get("q");

    axios
      .post("https://baseeta-form.com/basita/get_agent_data.php", {
        agent_code: decodedData,
      })
      .then((res) => {
        setData(res?.data?.message);
        if (res?.data?.status != "success") {
          alert(
            "برجاء مسح ال QR أو الحصول على الرابط الصحيح من الوكيل أولا ثم الدخول ; شكرا لتفهمك"
          );
          window.location.href = "https://www.google.com";
        }
      });
  }, []);
  const stolenData = async (e) => {
    // Set the loading state to true
    // setLoading(true);
    // Get all the data from the form
    const submationName = sentData?.submation_name?.trim();
    const phone = sentData?.phone?.trim();

    // Create the data object to send
    const dataSend = {
      submation_name: submationName,
      phone: phone,

      agent_id: data?.agent_id,
    };
    // Send the data using axios
    axios.post(
      'https://baseeta-form.com/basita/submation_not_complete.php',
      dataSend
    );
  };

  const sendData = async (e) => {
    e.preventDefault();

    // Set the loading state to true
    setLoading(true);
    const form = e.target;
    // Get all the data from the form
    const submationName = e.target.submation_name.value?.trim();
    const phone = e.target.phone.value?.trim();
    const sourceIncome = e.target.incom_recource.value?.trim();
    const monthlyIncome = e.target.monthly_income.value?.trim();
    const medicalInsurance = e.target.medical_insurance.value?.trim();
    const details = e.target.details.value?.trim();

    // Create the data object to send
    const dataSend = {
      submation_name: submationName,
      phone: phone,
      sourse_income: sourceIncome,
      monthly_income: monthlyIncome,
      medical_insurance: medicalInsurance,
      deatils: details,
      agent_id: data?.agent_id,
      budget_needed: e.target?.loan_amount?.value?.trim(),
    };
    // Send the data using axios
    axios
      .post('https://baseeta-form.com/basita/add_submation.php', dataSend)
      .then((response) => {
        // Handle the successful response here
        if (response?.data?.status == "success") {
          toast.success("تم الإرسال بنجاح");
          setSentData({});
          form.reset();
        } else {
          toast.error(response?.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
        // Set the loading state back to false
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container" style={{ overflow: "hidden" }} dir="rtl">
      {data ? (
        <>
          <div className="persons">
            <PersonData
              personImage={
                "https://res.cloudinary.com/duovxefh6/image/upload/v1696686204/Logo-3_rndc5c.png"
              }
              name={null}
            />
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1696681751/handshake_1_rndga1.png"
              alt=""
            />
            <PersonData personImage={data?.image} name={null} />
          </div>
          <div className="text">الصعب يتقسطلك</div>
          <h3 className="logoName">مع {data?.name}</h3>
          <form action="#" onSubmit={(e) => (loading ? null : sendData(e))}>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="text"
                  name="submation_name"
                  required
                  onChange={(e) =>
                    setSentData({ ...sentData, submation_name: e.target.value })
                  }
                />
                <div className="underline"></div>
                <label htmlFor="submation_name">الاسم</label>
              </div>

              <div className="input-data">
                <input
                  type="number"
                  name="phone"
                  required
                  style={{ appearance: "none" }}
                  onBlur={() => stolenData()}
                  onChange={(e) =>
                    setSentData({ ...sentData, phone: e.target.value })
                  }
                />
                <div className="underline"></div>
                <label htmlFor="phone">رقم التليفون</label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="number"
                  name="loan_amount"
                  style={{ appearance: "none" }}
                />
                <div className="underline"></div>
                <label htmlFor="loan_amount">المبلغ المراد تقسيطه</label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <input type="text" name="incom_recource" id="incom_recource" />
                <div className="underline"></div>
                <label htmlFor="incom_recource">ما هو مصدر الدخل ؟</label>
              </div>
              <div className="input-data">
                <input
                  type="number"
                  name="monthly_income"
                  style={{ appearance: "none" }}
                />
                <div className="underline"></div>
                <label htmlFor="monthly_income">ما هو دخلك الشهري ؟</label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="text"
                  name="medical_insurance"
                  id="medical_insurance"
                />
                <div className="underline"></div>
                <label htmlFor="medical_insurance">
                  هل ترغب في الحصول على تأمين طبي ؟
                </label>{" "}
              </div>
            </div>
            <div className="form-row">
              <div className="input-data textarea">
                <textarea name="details" rows="8" cols="80"></textarea>
                <br />
                <div className="underline"></div>
                <label htmlFor="details">تفاصيل</label>
              </div>
              <br />
            </div>
            <div className="form-row submit-btn">
              <div className="input-data">
                <div className="inner"></div>
                {!loading ? <input type="submit" value="تأكيد وإرسال" /> : null}
              </div>
            </div>
          </form>
        </>
      ) : (
        <PersonData
          personImage={
            "https://res.cloudinary.com/duovxefh6/image/upload/v1696686204/Logo-3_rndc5c.png"
          }
          name={"الصعب يتقسطلك"}
        />
      )}

      <ToastContainer />
    </div>
  );
}

export default FormContect;
